import React, { useMemo, useContext } from 'react';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { useTheme } from 'styled-components';
import { Flex, Text, Card, Button } from 'src/components/common';
import { getAppointDate, getAppointTime } from 'src/utils/date-parser';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { AppointmentStatus } from 'src/constants/AppointmentStatus';
import { Appointment } from 'src/types/Appointment';
import { InlineTextWrapper } from 'src/components/shared';
import { RolesEnum } from 'src/constants/RolesEnum';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { appointmentsAPI } from 'src/api/appointments/ApiRequests';
import { refreshCurrentUser } from 'src/store/slices/authSlice';
import { parseError } from 'src/utils/error-parser';
import { setSelectedSession } from 'src/store/slices/sessionSlice';
import { useTranslation } from 'react-i18next';
import { PaymentModal } from 'src/components/shared/payment-modal/PaymentModal';
import { getTherapistPriceBAM } from 'src/utils/price-calculator';
import { CompanyVoucher } from 'src/types/CompanyVoucher';

interface AllSessionsProps {
  startZoomSession: (session: Appointment) => void;
  startChat: (session: Appointment) => void;
  cancelSession: (session: Appointment) => void;
  bookSession: () => void;
}

export const AllSessions = ({ startZoomSession, startChat, cancelSession, bookSession }: AllSessionsProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);
  const { user } = useAppSelector((state: RootState) => state.auth);

  const { isOpen, close, open } = useDisclosure(false);
  const { session } = useAppSelector((state: RootState) => state.session);

  const allSessions = useMemo(() => {
    return (
      user?.appointments
        ?.filter((session) => session.paid)
        ?.filter((session) => sessionDidPass(session))
        ?.sort((a, b) => b.date - a.date) ?? []
    );
  }, [user]);

  const groupedSessions = useMemo(() => {
    const sessions = user?.appointments?.filter((session) => session.paid)?.filter((session) => sessionDidPass(session)) ?? [];

    const groups = sessions.reduce((groups, game) => {
      const date = getAppointDate(game.date).split(' u ')[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(game);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups)
      .map((date) => {
        return {
          date,
          sessions: groups[date].sort((a, b) => a.date - b.date),
        };
      })
      .sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    return groupArrays;
  }, [user]);

  const passedSessions = useMemo(() => {
    return (
      user?.appointments
        ?.filter((session) => session.paid)
        ?.filter((session) => !sessionDidPass(session))
        ?.sort((a, b) => b.date - a.date) ?? []
    );
  }, [user]);

  const unpaidSessions = useMemo(() => {
    return user?.appointments?.filter((session) => !session.paid)?.sort((a, b) => b.date - a.date) ?? [];
  }, [user]);

  const generateOrderNumber = useMemo(() => {
    return `PSHT-${new Date().getTime()}-${session?.date}`;
  }, [session]);

  function getDiffFromNowInMinutes(session: Appointment) {
    const diffMillisecons = new Date(session.date).getTime() - new Date().getTime();
    return diffMillisecons / 1000 / 60;
  }

  function sessionDidPass(session: Appointment): boolean {
    const extraHour = 3600000; // Hide start session button after session expires (session time 1h)
    const extra5min = 300000; // also add extra 5 minutes
    return new Date(session.date).getTime() + extraHour + extra5min >= new Date().getTime();
  }

  const closePayForAppointment = () => {
    dispatch(setSelectedSession(null));
    close();
  };

  const bookAppointment = async (orderNumber: string, panToken: string, voucher: CompanyVoucher) => {
    try {
      const response = await appointmentsAPI.bookPaidAppointment({
        sessionId: session.id,
        orderNumber: orderNumber,
        panToken: panToken,
        voucherId: voucher.id,
      });
      if (response.success) {
        ToastNotifications.success(t('msg_session_paid'), 'bookAppointment');
        await dispatch(refreshCurrentUser());
        closePayForAppointment();
      } else {
        ToastNotifications.error(response.error, 'bookAppointment');
      }
    } catch (error) {
      ToastNotifications.error(parseError(error), 'bookAppointment');
    }
  };

  const bookWithVoucher = async (voucher: string) => {
    try {
      const response = await appointmentsAPI.payAppointmentWithVoucher({
        appointmentId: session.id,
        voucher: voucher,
      });
      if (response.success) {
        ToastNotifications.success(t('msg_session_paid'), 'bookWithVoucher');
        await dispatch(refreshCurrentUser());
        closePayForAppointment();
      } else {
        ToastNotifications.error(response.error, 'bookWithVoucher');
      }
    } catch (error) {
      ToastNotifications.error(parseError(error), 'bookWithVoucher');
    }
  };

  return (
    <Flex flexDirection={'column'} width={'100%'} gap={'16px'} overflow={'scroll'}>
      <Text variant={'heading6'} text={t('sessions_1')} />

      <Card width={'100%'} borderRadius={'20px'} margin={isMobile ? '0 0 24px 0' : '0 0 48px 0'} padding={'24px'}>
        <Flex flexDirection="column" width={'100%'} height={'75vh'} gap={'32px'} overflow={'scroll'}>
          {user && user?.role != RolesEnum.THERAPIST && (
            <Flex width={'100%'} justifyContent={user.package?.sessions > 0 ? 'space-between' : 'flex-end'}>
              <Button variant={'accentDefault'} text={t('btn_book_new_session')} onClick={bookSession} />
            </Flex>
          )}

          {unpaidSessions.length === 0 && allSessions.length === 0 && passedSessions.length === 0 && (
            <Flex width={'100%'} height={'100%'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
              <Text variant={'heading6'} text={t('sessions_2')} />
            </Flex>
          )}

          {unpaidSessions.length > 0 && (
            <Flex flexDirection={'column'} width={'100%'} gap={'16px'}>
              <Text variant={'heading6'} text={t('sessions_3')} />
              {unpaidSessions?.map((appointment, index) => (
                <Flex key={index} flexDirection={'column'} width={'100%'} gap={'16px'}>
                  {appointment.therapist.id != user.id ? (
                    <Flex
                      width={'100%'}
                      flexDirection={isMobile ? 'column' : 'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      gap={'15px'}
                    >
                      <Text
                        variant={'paragraph1'}
                        text={`${t('sessions_4')} ${appointment.therapist.username}, ${getAppointDate(appointment.date)}`}
                      />
                      <Button
                        variant={'primaryDefault'}
                        text={t('btn_pay_for_session')}
                        onClick={() => {
                          dispatch(setSelectedSession(appointment));
                          open();
                        }}
                      />
                    </Flex>
                  ) : (
                    <Flex
                      width={'100%'}
                      flexDirection={isMobile ? 'column' : 'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      gap={'15px'}
                    >
                      <Text
                        variant={'paragraph1'}
                        text={`${t('sessions_4')} ${
                          appointment.therapist.id == user.id ? appointment.client.username : appointment.therapist.username
                        }, ${getAppointDate(appointment.date)}`}
                      />
                      <Button
                        variant={'secondaryRedDefault'}
                        text={t('btn_cancel')}
                        width={isMobile ? '100%' : 'auto'}
                        onClick={() => cancelSession(appointment)}
                      />
                    </Flex>
                  )}
                  <Flex width={'100%'} height={'0px'} border={`0.05px solid ${theme.colors.border.darker}`} margin={'0 0 16px 0'} />
                </Flex>
              ))}
            </Flex>
          )}

          {groupedSessions?.map((group, index) => (
            <Flex key={index} flexDirection={'column'} width={'100%'} margin={'0 0 16px 0'} gap={'16px'}>
              <Text variant={'heading6'} text={group.date} />

              {group.sessions?.map((appointment, index) => (
                <Flex key={index} flexDirection={'column'} width={'100%'} gap={'16px'}>
                  <Flex
                    flexDirection={isMobile ? 'column' : 'row'}
                    width={'100%'}
                    gap={'16px'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Flex flexDirection="column" gap={'8px'}>
                      <InlineTextWrapper>
                        <Text variant={'paragraph1'} text={`${t('sessions_5')} `} />
                        <Text variant={'paragraph1'} text={getAppointTime(appointment.date)} style={{ fontFamily: 'Barlow-Bold' }} />
                      </InlineTextWrapper>
                      <InlineTextWrapper>
                        <Text
                          variant={'paragraph1'}
                          text={appointment.therapist.id == user.id ? `${t('sessions_6')} ` : `${t('sessions_7')} `}
                        />
                        <Text
                          variant={'paragraph1'}
                          text={appointment.therapist.id == user.id ? appointment.client.username : appointment.therapist.username}
                          style={{ fontFamily: 'Barlow-Bold' }}
                        />
                      </InlineTextWrapper>
                    </Flex>

                    <Flex flexDirection={isMobile ? 'column' : 'row'} gap="10px" width={isMobile ? '100%' : 'auto'}>
                      {appointment.status === AppointmentStatus.ACCEPTED && sessionDidPass(appointment) && (
                        <Button
                          variant={'accentDefault'}
                          text={t('btn_start_session')}
                          width={isMobile ? '100%' : 'auto'}
                          disabled={getDiffFromNowInMinutes(appointment) > 5}
                          onClick={() => startZoomSession(appointment)}
                        />
                      )}

                      {appointment.status === AppointmentStatus.PENDING && (
                        <Button
                          variant={'primaryDefault'}
                          text={t('btn_session_on_hold')}
                          minWidth="250px"
                          width={isMobile ? '100%' : 'auto'}
                          disabled
                        />
                      )}

                      {appointment.status === AppointmentStatus.REJECTED && (
                        <Button
                          variant={'primaryDefault'}
                          text={t('btn_session_canceled')}
                          minWidth="250px"
                          width={isMobile ? '100%' : 'auto'}
                          disabled
                        />
                      )}

                      <Button
                        variant={'secondaryAccentDefault'}
                        text={t('btn_send_message')}
                        width={isMobile ? '100%' : 'auto'}
                        onClick={() => startChat(appointment)}
                      />

                      <Button
                        variant={'secondaryRedDefault'}
                        text={t('btn_cancel')}
                        width={isMobile ? '100%' : 'auto'}
                        onClick={() => cancelSession(appointment)}
                      />
                    </Flex>
                  </Flex>

                  <Flex width={'100%'} height={'0px'} border={`0.05px solid ${theme.colors.border.darker}`} />
                </Flex>
              ))}
            </Flex>
          ))}

          {passedSessions.length > 0 && (
            <Flex flexDirection={'column'} width={'100%'} margin={'0 0 32px 0'} gap={'16px'}>
              <Text variant={'heading6'} text={t('sessions_8')} />

              {passedSessions?.map((appointment, index) => (
                <Flex key={index} flexDirection={'column'} width={'100%'} gap={'16px'}>
                  <Flex
                    flexDirection={isMobile ? 'column' : 'row'}
                    width={'100%'}
                    gap={'16px'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      variant={'paragraph1'}
                      text={`${t('sessions_4')} ${
                        appointment.therapist.id == user.id ? appointment.client.username : appointment.therapist.username
                      }, ${getAppointDate(appointment.date)}`}
                    />
                  </Flex>
                  <Flex width={'100%'} height={'0px'} border={`0.05px solid ${theme.colors.border.darker}`} />
                </Flex>
              ))}
            </Flex>
          )}
        </Flex>
      </Card>

      <PaymentModal
        isOpen={isOpen}
        amount={getTherapistPriceBAM(session?.therapist?.price)}
        orderNumber={generateOrderNumber}
        enableVoucher={true}
        onClose={closePayForAppointment}
        paymentSuccessful={bookAppointment}
        payWithVoucher={bookWithVoucher}
      />
    </Flex>
  );
};
